<template>
    <div class="row">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Page Not Found</h5>
                <p>
                    The page you were looking for couldnt not be found.
                </p>
            </div>
        </div>
    </div>
</template>
